(function ($) {
    function add_marker($marker, map) {

        var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

        var icon_size = 122;

        if (jQuery('html').hasClass('mobile')) {
            icon_size = 75;
        }

        var icon = {
            url: "//clients.thekitchen.agency/hemmi/hemmi_map_icon.png",
            scaledSize: new google.maps.Size(66, 106),
        };

        var marker = new google.maps.Marker({
            position: latlng,
            //icon        : icon,
            //animation   : google.maps.Animation.DROP,
            size: new google.maps.Size(66, 106),
            map: map
        });



        map.markers.push(marker);

       /* if ($marker.html()) {

            var infowindow = new google.maps.InfoWindow({
                content: $marker.html()
            });

            google.maps.event.addListener(marker, 'click', function () {
                infowindow.open(map, marker);
            });
        }*/

        var iwContent = $marker.attr('data-address').split(',');

        var contentString = '<div id="content">'+
            '<div id="siteNotice">'+
            '</div>'+
            '<h3 id="firstHeading" class="firstHeading">'+iwContent[0]+'</h3>'+
            '<p class="">'+iwContent[1]+'<br/>'+iwContent[2]+'</p>'+
            '<div style="float:left;width:125px; text-align:center;"><a class="route" href="https://www.google.com/url?sa=t&amp;rct=j&amp;q=&amp;esrc=s&amp;source=web&amp;cd=17&amp;cad=rja&amp;uact=8&amp;ved=0CNIBEPUXMBA&amp;url=http%3A%2F%2Fmaps.google.com%2Fmaps%3Fie%3DUTF-8%26hq%3Dthe%2Bdolder%2Bgrand%2Bz%25C3%25BCrich%26daddr%3DKurhausstrasse%2B65%2C%2B8032%2BZ%25C3%25BCrich%2C%2BSchweiz%26panel%3D1%26f%3Dd%26fb%3D1%26geocode%3DFYTZ0gId49CCACld4HkVz6CaRzEBQRUHKHE3mg%26cid%3D11112475022291452161&amp;ei=-9n0U5OhEKOJ7Aan54AI&amp;usg=AFQjCNHoRekdm-lw0VXIjKhl78AgJ9jwdA&amp;sig2=UaRWpomm-7h2opkbAW9Jrw&amp;bvm=bv.73231344,d.ZGU" target="_blank">Routenplaner</a></div>' +
            '</div>';

        var infowindow = new google.maps.InfoWindow({
            content: contentString
        });

        infowindow.open(map,marker);

        google.maps.event.addListener(marker, 'click', function() {
            infowindow.open(map,marker);
        });




    }

    function center_map(map) {
        var bounds = new google.maps.LatLngBounds();
        var latlng;

        $.each(map.markers, function (i, marker) {
            latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
            bounds.extend(latlng);
        });

        if (map.markers.length === 1) {
            map.setCenter(bounds.getCenter());
            map.setZoom(17);
            map.panBy(0, 0);
        } else {
            // fit to bounds
            map.fitBounds(bounds);
        }
    }

    function new_map($el) {
        var $markers = $el.find('.marker');

        var args = {
            zoom: 18,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false,
            navigationControl: false,
            mapTypeControl: false,
            scaleControl: false,
            draggable: true,
            disableDefaultUI: true,
            styles: [{
                "featureType": "landscape.man_made",
                "elementType": "geometry",
                "stylers": [{"visibility": "simplified"}, {"hue": "#ff0000"}, {"saturation": "-100"}]
            }, {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [{"visibility": "off"}]
            }, {
                "featureType": "road.highway.controlled_access",
                "elementType": "all",
                "stylers": [{"visibility": "on"}]
            }, {
                "featureType": "road.arterial",
                "elementType": "all",
                "stylers": [{"visibility": "on"}]
            }, {
                "featureType": "road.local",
                "elementType": "all",
                "stylers": [{"visibility": "on"}, {"saturation": "-35"}, {"weight": "1.55"}]
            }, {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [{"visibility": "off"}, {"hue": "#ff0000"}, {"saturation": "-100"}]
            }, {
                "featureType": "road.local",
                "elementType": "geometry.fill",
                "stylers": [{"visibility": "on"}, {"hue": "#ff0000"}, {"saturation": "-100"}, {"weight": "0.50"}]
            }, {
                "featureType": "road.local",
                "elementType": "geometry.stroke",
                "stylers": [{"visibility": "off"}]
            }, {
                "featureType": "transit.station",
                "elementType": "all",
                "stylers": [{"visibility": "on"}, {"hue": "#ff0000"}, {"saturation": "-100"}]
            }, {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [{"visibility": "off"}]
            }, {
                "featureType": "transit.station",
                "elementType": "geometry.fill",
                "stylers": [{"visibility": "on"}]
            }, {
                "featureType": "water",
                "elementType": "all",
                "stylers": [{"visibility": "on"}, {"hue": "#ff0000"}, {"saturation": "-100"}]
            }]
        };

        var map = new google.maps.Map($el[0], args);
        map.markers = [];

        $markers.each(function () {
            add_marker($(this), map);
        });


        center_map(map);

        return map;

    }


    var map = null;

    $(document).ready(function () {
        $('.gmap').each(function () {
            // create map
            map = new_map($(this));
        });
    });

})(jQuery);




